
import {
    defineComponent, PropType, ref, Ref, watch
} from 'vue';
import {
    ListHeaderItem, list
} from '@/components/common/list';
import { account } from '@/data/index';
import { DistributorTableData } from './all.type';

const headers: Array<ListHeaderItem> = [{
    name: 'Account2',
    label: WordList.TabelResetPwInHtmlAccount
}, {
    name: 'Email',
    label: WordList.TabelPropertyManageEmail
}, {
    name: 'Phone',
    label: WordList.TabelPersonUserInHtmlPhone
}];

export default defineComponent({
    components: {
        list
    },
    props: {
        account: {
            type: String,
            default: ''
        },
        selectItem: {
            type: Array as PropType< Array<string> >,
            default: () => []
        }
    },
    setup(props, { emit }) {
        const updateToList = ref(0);
        const listRequestData: Ref<Array<DistributorTableData>> = ref([{
            Account2: '',
            Email: '',
            Phone: ''
        }]);
        account.getSuperFirmwareDialogTableData('', (res: Array<DistributorTableData>) => {
            listRequestData.value = res;
            updateToList.value += 1;
        });

        const updateSelectData = (selectIds: Array<string>) => {
            emit('updateSelectData', selectIds);
        };

        // 监听搜索框account是否改变，改变请求新的dis列表
        watch(() => props.account, (newAccount, oldAccount) => {
            if (newAccount !== oldAccount) {
                account.getSuperFirmwareDialogTableData(newAccount, (res: Array<DistributorTableData>) => {
                    listRequestData.value = res;
                    updateToList.value += 1;
                });
            }
        });
        return {
            headers,
            updateToList,
            listRequestData,
            updateSelectData
        };
    }
});
